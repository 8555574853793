import { Link } from 'gatsby'
import React from 'react'
import profileImage from '../assets/images/angelika-behrenberg.jpg'
import Layout from '../components/layout'

class Index extends React.Component {
  render() {
    return (
      <Layout seoTitle="Praxis für Psychotherapie Telgte | Angelika Behrenberg">
        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Angelika Behrenberg</h2>
                </header>
                <p>
                  <span>
                    Dipl.-Psychologin, Psychologische Psychotherapeutin,{' '}
                  </span>
                  Supervisorin DGSv und&nbsp;BDP&nbsp; und Trainerin für
                  Gruppendynamik DGGO
                </p>
                <p>
                  Als <strong>Psychologische Psychotherapeutin</strong> arbeite
                  ich auf dem Hintergrund meiner Ausbildungen als
                  Gesprächspsychotherapeutin und Verhaltenstherapeutin ambulant
                  mit Menschen in aktuellen Krisensituationen bzw.
                  längerfristigen persönlichen Belastungen und psychischen
                  Erkrankungen, z.B. Ängsten, Depressionen, Schwierigkeiten bei
                  der Bewältigung des Alltags oder Schwierigkeiten im Kontakt
                  mit anderen Menschen. Sofern nötig, können Partner und
                  Angehörige einbezogen werden.
                </p>
              </div>

              <span className="image">
                <img src={profileImage} alt="Angelika Behrenberg" />
              </span>
            </div>

            <div className="spotlight">
              <div className="content">
                <p>
                  <strong> Ziele der Psychotherapie</strong> sind allgemein die
                  Bewältigung der Probleme und Störungen des Wohlbefindens oder
                  Selbstwertgefühls und die Wiedererlangung von Lebensqualität.
                  Um dies zu erreichen, werden individuelle Ziele vereinbart und
                  konkrete Veränderungsschritte in den gemeinsam erarbeiteten
                  Problembereichen umgesetzt.
                </p>
                <p>
                  Die <strong>Kosten</strong> der Behandlung übernehmen die
                  Gesetzlichen Krankenkassen und private Krankenversicherungen.
                  Hierzu ist für gesetzlich Versicherte die Vorlage der
                  Krankenversicherungskarte &nbsp;erforderlich.
                </p>
                <p>
                  Ein <strong>Erstgespräch</strong> im Rahmen meiner
                  <strong> Psychotherapeutischen Sprechstunde</strong>{' '}
                  vereinbaren Sie telefonisch unter 02504/739595 oder per{' '}
                  <a href="mailto:a.behrenberg@tfbs.de">Email</a>. Erreichbar
                  bin ich in meinen <strong>Telefonsprechzeiten</strong> von
                  Montag 8:30-10:00 Uhr sowie von DIenstag bis Freitag 8.30-9.00
                  Uhr.
                </p>
                <ul className="actions">
                  <li>
                    <Link to="/psychotherapie" className="button">
                      Mehr zur Psychotherapie
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
